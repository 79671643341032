import React, { Component } from "react";
import "./PreWedding.css";
import Header from "../../components/Header/Header";
import LightGallery from "lightgallery/react";
import fjGallery from "flickr-justified-gallery";
import photo_0 from "../../assets/Pre-Wedding/Drashti & Manthan/DSC00506.jpg";
import photo_1 from "../../assets/Pre-Wedding/Drashti & Manthan/3M3A0943.jpg";
import photo_2 from "../../assets/Pre-Wedding/Drashti & Manthan/DSC00328.jpg";
import photo_3 from "../../assets/Pre-Wedding/Drashti & Manthan/DSC00302.jpg";
import photo_4 from "../../assets/Pre-Wedding/Drashti & Manthan/DSC09905.jpg";
import photo_5 from "../../assets/Pre-Wedding/Drashti & Manthan/DSC00288.jpg";
import photo_6 from "../../assets/Pre-Wedding/Drashti & Manthan/3M3A0944.jpg";
import photo_7 from "../../assets/Pre-Wedding/Drashti & Manthan/DSC09337.jpg";
import photo_8 from "../../assets/Pre-Wedding/Drashti & Manthan/DSC09860.jpg";
import photo_9 from "../../assets/Pre-Wedding/Drashti & Manthan/DSC00272.jpg";
import photo_10 from "../../assets/Pre-Wedding/Drashti & Manthan/DSC00500.jpg";
import photo_11 from "../../assets/Pre-Wedding/Drashti & Manthan/DSC09849.jpg";
import photo_12 from "../../assets/Pre-Wedding/Drashti & Manthan/DSC09444.jpg";
import photo_13 from "../../assets/Pre-Wedding/Drashti & Manthan/DSC00489.jpg";
import photo_14 from "../../assets/Pre-Wedding/Drashti & Manthan/DSC09283.jpg";
import photo_15 from "../../assets/Pre-Wedding/Drashti & Manthan/DSC00339.jpg";
import photo_16 from "../../assets/Pre-Wedding/Drashti & Manthan/DSC00028.jpg";
import photo_17 from "../../assets/Pre-Wedding/Drashti & Manthan/DSC09959.jpg";
import photo_18 from "../../assets/Pre-Wedding/Drashti & Manthan/DSC09421.jpg";
import photo_19 from "../../assets/Pre-Wedding/Drashti & Manthan/DSC09386.jpg";
import photo_20 from "../../assets/Pre-Wedding/Drashti & Manthan/DSC09231.jpg";
import photo_21 from "../../assets/Pre-Wedding/Drashti & Manthan/3M3A0924.jpg";
import photo_22 from "../../assets/Pre-Wedding/Drashti & Manthan/DSC09814.jpg";
import photo_23 from "../../assets/Pre-Wedding/Drashti & Manthan/DSC09237.jpg";
import photo_24 from "../../assets/Pre-Wedding/Drashti & Manthan/DSC09825.jpg";
import photo_25 from "../../assets/Pre-Wedding/Drashti & Manthan/DSC09428.jpg";
import photo_26 from "../../assets/Pre-Wedding/Drashti & Manthan/DSC09238.jpg";
import photo_27 from "../../assets/Pre-Wedding/Drashti & Manthan/DSC00382.jpg";
import photo_28 from "../../assets/Pre-Wedding/Drashti & Manthan/3M3A0916.jpg";
import photo_29 from "../../assets/Pre-Wedding/Drashti & Manthan/DSC09503.jpg";
import photo_30 from "../../assets/Pre-Wedding/Drashti & Manthan/DSC09716.jpg";
import photo_31 from "../../assets/Pre-Wedding/Drashti & Manthan/DSC00332.jpg";
import photo_32 from "../../assets/Pre-Wedding/Drashti & Manthan/DSC09464.jpg";
import photo_33 from "../../assets/Pre-Wedding/Drashti & Manthan/DSC00050.jpg";
import photo_34 from "../../assets/Pre-Wedding/Drashti & Manthan/3M3A1058.jpg";
import photo_35 from "../../assets/Pre-Wedding/Drashti & Manthan/DSC09262.jpg";
import photo_36 from "../../assets/Pre-Wedding/Drashti & Manthan/3M3A0838.jpg";
import photo_37 from "../../assets/Pre-Wedding/Drashti & Manthan/DSC09895.jpg";
import photo_38 from "../../assets/Pre-Wedding/Drashti & Manthan/DSC09249.jpg";
import photo_39 from "../../assets/Pre-Wedding/Drashti & Manthan/DSC00495.jpg";

import photo_40 from "../../assets/Pre-Wedding/Kajol & Parth/DSC03966.jpg";
import photo_41 from "../../assets/Pre-Wedding/Kajol & Parth/DSC04164.jpg";
import photo_42 from "../../assets/Pre-Wedding/Kajol & Parth/DSC03977.jpg";
import photo_43 from "../../assets/Pre-Wedding/Kajol & Parth/DSC04139.jpg";
import photo_44 from "../../assets/Pre-Wedding/Kajol & Parth/DSC04103.jpg";
import photo_45 from "../../assets/Pre-Wedding/Kajol & Parth/DSC04086.jpg";
import photo_46 from "../../assets/Pre-Wedding/Kajol & Parth/DSC04124.jpg";
import photo_47 from "../../assets/Pre-Wedding/Kajol & Parth/DSC04080.jpg";
import photo_48 from "../../assets/Pre-Wedding/Kajol & Parth/DSC04068.jpg";
import photo_49 from "../../assets/Pre-Wedding/Kajol & Parth/DSC04026.jpg";
import photo_50 from "../../assets/Pre-Wedding/Kajol & Parth/DSC03997.jpg";
import photo_51 from "../../assets/Pre-Wedding/Kajol & Parth/DSC04034.jpg";
import photo_52 from "../../assets/Pre-Wedding/Kajol & Parth/DSC04008.jpg";
import CopyRights from "../../components/CopyRights/CopyRights";

export default class PreWedding extends Component {
  constructor(props) {
    super(props);

    this.state = {
      photos: [
        { id: 0, src: photo_0 },
        { id: 1, src: photo_1 },
        { id: 2, src: photo_2 },
        { id: 3, src: photo_3 },
        { id: 4, src: photo_4 },
        { id: 5, src: photo_5 },
        { id: 6, src: photo_6 },
        { id: 7, src: photo_7 },
        { id: 8, src: photo_8 },
        { id: 9, src: photo_9 },
        { id: 10, src: photo_10 },
        { id: 11, src: photo_11 },
        { id: 12, src: photo_12 },
        { id: 13, src: photo_13 },
        { id: 14, src: photo_14 },
        { id: 15, src: photo_15 },
        { id: 16, src: photo_16 },
        { id: 17, src: photo_17 },
        { id: 18, src: photo_18 },
        { id: 19, src: photo_19 },
        { id: 20, src: photo_20 },
        { id: 21, src: photo_21 },
        { id: 22, src: photo_22 },
        { id: 23, src: photo_23 },
        { id: 24, src: photo_24 },
        { id: 25, src: photo_25 },
        { id: 26, src: photo_26 },
        { id: 27, src: photo_27 },
        { id: 28, src: photo_28 },
        { id: 29, src: photo_29 },
        { id: 30, src: photo_30 },
        { id: 31, src: photo_31 },
        { id: 32, src: photo_32 },
        { id: 33, src: photo_33 },
        { id: 34, src: photo_34 },
        { id: 35, src: photo_35 },
        { id: 36, src: photo_36 },
        { id: 37, src: photo_37 },
        { id: 38, src: photo_38 },
        { id: 39, src: photo_39 },
        { id: 40, src: photo_40 },
        { id: 41, src: photo_41 },
        { id: 42, src: photo_42 },
        { id: 43, src: photo_43 },
        { id: 44, src: photo_44 },
        { id: 45, src: photo_45 },
        { id: 46, src: photo_46 },
        { id: 47, src: photo_47 },
        { id: 48, src: photo_48 },
        { id: 49, src: photo_49 },
        { id: 50, src: photo_50 },
        { id: 51, src: photo_51 },
        { id: 52, src: photo_52 },
      ],
    };
  }

  componentDidMount() {
    fjGallery(document.querySelectorAll(".gallery"), {
      itemSelector: ".gallery__item",
      rowHeight: 800,
      lastRow: "start",
      gutter: 2,
      rowHeightTolerance: 0.1,
      calculateItemsHeight: true,
    });
  }
  render() {
    return (
      <div className="relative h-screen w-screen overflow-y-auto">
        {/* <BackGround show_bg_img={false} /> */}
        <Header />
        <div className="relative z-10 min-h-screen flex flex-col items-center justify-center pt-20">
          <h1 className="text-4xl text-white font-bold mb-8 text-center">
            Pre Wedding
          </h1>

          <LightGallery
            plugins={[]}
            mode="lg-fade"
            pager={false}
            thumbnail={false}
            galleryId={"prewedding"}
            autoplayFirstVideo={false}
            elementClassNames={"gallery"}
            mobileSettings={{
              controls: false,
              showCloseIcon: true,
              download: false,
              rotate: false,
            }}
          >
            {this.state.photos.map((photo) => (
              <a
                className="gallery__item relative group cursor-pointer p-1"
                data-src={photo.src}
                key={photo.index}
                href={photo.src}
              >
                <img
                  className="img-responsive w-full h-full object-cover"
                  src={photo.src}
                  alt={photo.name}
                />
              </a>
            ))}
          </LightGallery>
        </div>
        <CopyRights />
      </div>
    );
  }
}
