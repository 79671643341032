import React, { Component } from "react";
import Header from "../../components/Header/Header";
import { NavLink } from "react-router-dom";

import img1 from "../../assets/background_images/DSC00050.jpg";
import img2 from "../../assets/background_images/DSC00382.jpg";
import img3 from "../../assets/background_images/DSC09238.jpg";
import img4 from "../../assets/background_images/DSC09283.jpg";
import img5 from "../../assets/background_images/VBP_1162.jpg";
import img6 from "../../assets/background_images/VBP_1172.jpg";
import img7 from "../../assets/background_images/VBP_1181.jpg";
import img8 from "../../assets/background_images/VBP_1333.jpg";
import CopyRights from "../../components/CopyRights/CopyRights";
import Footer from "../../components/Footer/Footer";
import Services from "../../components/Services/Services";
import Portfolio from "../../components/Portfolio/Portfolio";

export default class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentImageIndex: 0,
      images: [img1, img2, img3, img4, img5, img6, img7, img8],
    };

    this.nextImage = this.nextImage.bind(this);
  }

  componentDidMount() {
    this.interval = setInterval(this.nextImage, 5000); // Change image every 5 seconds
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  nextImage() {
    const { currentImageIndex, images } = this.state;
    this.setState({
      currentImageIndex: (currentImageIndex + 1) % images.length,
    });
  }

  render() {
    return (
      // <div className="relative h-screen w-full">
      //   <Header />
      //   <div className="absolute inset-0 overflow-hidden h-3/4">
      //     <img
      //       src={this.state.images[this.state.currentImageIndex]}
      //       alt="Slideshow"
      //       className="w-full h-full object-cover transition-opacity duration-1000 opacity-100"
      //       style={{ opacity: 1 }}
      //     />
      //     <div className="absolute inset-0 bg-black opacity-50"></div>
      //     <div className="absolute inset-0 flex items-center justify-center">
      //       <h1 className="text-4xl md:text-6xl font-bold text-white text-center">
      //         Welcome to Our Wedding Photography
      //       </h1>
      //     </div>
      //   </div>
      // </div>

      // <div>
      //   <div className="relative h-screen w-full">
      //     <Header />
      //     <div className="absolute inset-0 overflow-hidden h-3/4">
      //       {this.state.images.map((image, index) => (
      //         <img
      //           key={index}
      //           src={image}
      //           alt={`Slide ${index}`}
      //           className={`w-full h-full object-cover absolute top-0 left-0 transition-opacity duration-1000 ${
      //             index === this.state.currentImageIndex
      //               ? "opacity-100"
      //               : "opacity-0"
      //           }`}
      //         />
      //       ))}
      //       <div className="absolute inset-0 bg-black opacity-50"></div>
      //       <div className="absolute inset-0 flex items-center justify-center">
      //         <h1 className="text-4xl md:text-6xl font-bold text-white text-center">
      //           Welcome to Our Wedding Photography
      //         </h1>
      //       </div>
      //     </div>
      //   </div>
      // </div>

      <div>
        <div className="relative h-screen w-full">
          <Header />
          <div className="absolute inset-0 overflow-hidden">
            {this.state.images.map((image, index) => (
              <img
                key={index}
                src={image}
                alt={`Slide ${index}`}
                className={`w-full h-full object-cover absolute top-0 left-0 transition-opacity duration-1000 ${
                  index === this.state.currentImageIndex
                    ? "opacity-100"
                    : "opacity-0"
                }`}
              />
            ))}
            <div className="absolute inset-0 bg-black opacity-50"></div>
            <div className="absolute inset-0 flex flex-col items-center justify-center">
              <h1 className="text-4xl md:text-6xl font-bold text-white text-center">
                From Vows To Forever
              </h1>
              <div className="mt-10">
                <NavLink to="/contact">
                  <button className=" text-2xl text-center py-2 px-4 font-bold rounded-lg duration-100 bg-black text-white hover:bg-[#BF9E0B] hover:text-white">
                    Book Now
                  </button>
                </NavLink>
              </div>
            </div>
          </div>
        </div>

        <Portfolio />

        <Services />

        <Footer />

        <CopyRights />
      </div>

      // <div className="relative h-screen w-screen">
      //  <div>
      //  <Header />
      // <div className="absolute inset-0 flex flex-col md:flex-row items-center justify-center md:justify-between text-gray-300 space-y-4">
      //     <div className="hidden md:flex flex-col items-center space-y-4 p-4">
      //       <a
      //         href="https://www.instagram.com/theweddingsvow_official/"
      //         target="_blank"
      //         rel="noopener noreferrer"
      //       >
      //         <FaInstagram size={24} className="hover:cursor-pointer" />
      //       </a>
      //       <a
      //         href="https://www.facebook.com/people/The-Weddings-Vow/61562682080548/"
      //         target="_blank"
      //         rel="noopener noreferrer"
      //       >
      //         <FaFacebook size={24} className=" hover:cursor-pointer" />
      //       </a>
      //     </div>

      //     <div className="hidden md:flex flex-col items-center space-y-4 p-4">
      //       <a href="https://api.whatsapp.com/send?phone=+919664616504">
      //         <FaWhatsapp
      //           size={24}
      //           className="hover:cursor-pointer text-gray-300"
      //         />
      //       </a>
      //       <a href="mailto:theweddingsvow@gmail.com">
      //         <FaEnvelope
      //           size={24}
      //           className="hover:cursor-pointer text-gray-300"
      //         />
      //       </a>
      //     </div>
      //   </div>

      //   <div className="absolute bottom-4 left-0 right-0 md:hidden flex justify-center space-x-4 p-4 text-gray-300">
      //     <FaInstagram size={24} className=" hover:cursor-pointer" />
      //     <FaFacebook size={24} className=" hover:cursor-pointer" />
      //   </div>
      // </div>
    );
  }
}
