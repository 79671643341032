import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default class Services extends Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 2000,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
    return (
      <div className="bg-white py-16">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
          <p className="text-5xl font-extrabold text-gray-900 mb-8">
            Our <span className="text-[#BF9E0B]">Services</span>
          </p>
          <Slider {...settings}>
            <div className="p-4">
              <div className="bg-[rgb(62,62,62)] p-8 shadow-lg rounded-lg">
                <div className="text-6xl text-[#BF9E0B]">
                  <ion-icon name="camera"></ion-icon>
                </div>
                <h3 className="text-xl font-bold mb-4 text-white">
                  Photography
                </h3>
                <p className="text-gray-100">
                  Capturing moments with timeless elegance.
                </p>
              </div>
            </div>
            <div className="p-4">
              <div className="bg-[rgb(62,62,62)] p-8 shadow-lg rounded-lg">
                <div className="text-6xl text-[#BF9E0B]">
                  <ion-icon name="videocam"></ion-icon>
                </div>
                <h3 className="text-xl font-bold mb-4 text-white">
                  Cinematography
                </h3>
                <p className="text-gray-100">
                  Cinematic storytelling through lens mastery.
                </p>
              </div>
            </div>
            <div className="p-4">
              <div className="bg-[rgb(62,62,62)] p-8 shadow-lg rounded-lg">
                <div className="text-6xl text-[#BF9E0B]">
                  <ion-icon name="images"></ion-icon>
                </div>
                <h3 className="text-xl font-bold mb-4 text-white">Podcasts</h3>
                <p className="text-gray-100">
                  Visualizing audio stories with creative flair.
                </p>
              </div>
            </div>
            <div className="p-4">
              <div className="bg-[rgb(62,62,62)] p-8 shadow-lg rounded-lg">
                <div className="text-6xl text-[#BF9E0B]">
                  <ion-icon name="aperture"></ion-icon>
                </div>
                <h3 className="text-xl font-bold mb-4 text-white">
                  Couple Shoots
                </h3>
                <p className="text-gray-100">
                  Celebrating love through intimate photography.
                </p>
              </div>
            </div>
            <div className="p-4">
              <div className="bg-[rgb(62,62,62)] p-8 shadow-lg rounded-lg">
                <div className="text-6xl text-[#BF9E0B]">
                  <ion-icon name="image"></ion-icon>
                </div>
                <h3 className="text-xl font-bold mb-4 text-white">
                  Event Shoots
                </h3>
                <p className="text-gray-100">
                  Documenting occasions with artistic precision.
                </p>
              </div>
            </div>
            <div className="p-4">
              <div className="bg-[rgb(62,62,62)] p-8 shadow-lg rounded-lg">
                <div className="text-6xl text-[#BF9E0B]">
                  <ion-icon name="brush"></ion-icon>
                </div>
                <h3 className="text-xl font-bold mb-4 text-white">
                  Post production
                </h3>
                <p className="text-gray-100">
                  Elevating your images through expert refinement.
                </p>
              </div>
            </div>
          </Slider>
        </div>
      </div>
    );
  }
}
