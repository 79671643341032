import React, { Component } from "react";
import {
  FaInstagram,
  FaFacebook,
  FaEnvelope,
  FaWhatsapp,
} from "react-icons/fa";

export default class Footer extends Component {
  render() {
    return (
      <div className=" bg-[rgb(57,13,13)] py-10 pb-0">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
          <p className="text-5xl font-extrabold text-white mb-8">
            Get In <span className=" text-[#BF9E0B] ">Touch</span>
          </p>
        </div>
        <div className="text-white text-center text-2xl py-5 pt-0">
          <p>+91 94090 60204</p>
          <p>theweddingsvow@gmail.com</p>
        </div>
        <div className="flex justify-center space-x-4 pb-10 pt-0 text-white">
          <a
            href="https://www.instagram.com/theweddingsvow_official/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaInstagram size={50} className="hover:cursor-pointer" />
          </a>
          <a
            href="https://www.facebook.com/people/The-Weddings-Vow/61562682080548/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaFacebook size={50} className="hover:cursor-pointer" />
          </a>
          <a
            href="https://api.whatsapp.com/send?phone=+919409060204"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaWhatsapp size={50} className="hover:cursor-pointer" />
          </a>
          <a
            href="mailto:theweddingsvow@gmail.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaEnvelope size={50} className="hover:cursor-pointer" />
          </a>
        </div>
      </div>
    );
  }
}
