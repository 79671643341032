import React, { Component } from "react";
import Logo from "../../assets/Logo_2.png";
import Name from "../../assets/Name.png";
import { NavLink } from "react-router-dom";

export default class Header extends Component {
  constructor(props) {
    super(props);

    this.state = { isMenuOpen: false };

    this.toggleMenu = this.toggleMenu.bind(this);
  }

  toggleMenu(event) {
    this.setState((prevState) => ({
      isMenuOpen: !prevState.isMenuOpen,
    }));
  }
  render() {
    return (
      <div className="fixed top-0 left-0 right-0 z-50">
        <header className="bg-[rgb(57,13,13)]">
          <nav className=" px-4 lg:px-6 py-2.5 dark:bg-transparent">
            <div className="flex flex-wrap justify-between items-center mx-auto max-w-screen-xl">
              <a
                href="https://theweddingsvow.com"
                className="flex items-center"
              >
                <img src={Logo} alt="Logo" className="h-8 md:h-8 mr-4" />
                <img src={Name} alt="Name" className="h-8 md:h-8 mr-4 mt-4" />
              </a>
              <div className="flex items-center lg:order-2">
                <button
                  onClick={this.toggleMenu}
                  type="button"
                  className="inline-flex items-center p-2 ml-1 text-sm text-gray-500 rounded-lg lg:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
                  aria-controls="mobile-menu"
                  aria-expanded={this.state.isMenuOpen}
                >
                  <span className="sr-only">Open main menu</span>
                  <svg
                    className={`w-6 h-6 ${
                      this.state.isMenuOpen ? "hidden" : "block"
                    }`}
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                  <svg
                    className={`w-6 h-6 ${
                      this.state.isMenuOpen ? "block" : "hidden"
                    }`}
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </button>
              </div>
              <div
                className={`${
                  this.state.isMenuOpen ? "block" : "hidden"
                } justify-between items-center w-full lg:flex lg:w-auto lg:order-1 transition-transform duration-1000 bg-[rgba(0,0,0,0.67)] md:bg-transparent rounded-xl`}
                id="mobile-menu"
              >
                <ul className="flex flex-col mt-4 font-bold lg:flex-row lg:space-x-8 lg:mt-0">
                  <li>
                    <NavLink
                      to="/"
                      onClick={this.toggleMenu}
                      className={({ isActive }) =>
                        isActive
                          ? "text-xl block py-2 pr-4 pl-3 text-white rounded bg-primary-700 lg:bg-transparent lg:text-primary-700 lg:p-0 dark:text-white"
                          : "text-xl block py-2 pr-4 pl-3 text-gray-700 lg:p-0 dark:text-gray-400 hover:text-[#BF9E0B]"
                      }
                    >
                      Home
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/wedding"
                      onClick={this.toggleMenu}
                      className={({ isActive }) =>
                        isActive
                          ? "text-xl block py-2 pr-4 pl-3 text-white rounded bg-primary-700 lg:bg-transparent lg:text-primary-700 lg:p-0 dark:text-white"
                          : "text-xl block py-2 pr-4 pl-3 text-gray-700 lg:p-0 dark:text-gray-400 hover:text-[#BF9E0B]"
                      }
                    >
                      Wedding
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/prewedding"
                      onClick={this.toggleMenu}
                      className={({ isActive }) =>
                        isActive
                          ? "text-xl block py-2 pr-4 pl-3 text-white rounded bg-primary-700 lg:bg-transparent lg:text-primary-700 lg:p-0 dark:text-white"
                          : "text-xl block py-2 pr-4 pl-3 text-gray-700 lg:p-0 dark:text-gray-400 hover:text-[#BF9E0B]"
                      }
                    >
                      PreWedding
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/contact"
                      onClick={this.toggleMenu}
                      className={({ isActive }) =>
                        isActive
                          ? "text-xl block py-2 pr-4 pl-3 text-white rounded bg-primary-700 lg:bg-transparent lg:text-primary-700 lg:p-0 dark:text-white"
                          : "text-xl block py-2 pr-4 pl-3 text-gray-700 lg:p-0 dark:text-gray-400 hover:text-[#BF9E0B]"
                      }
                    >
                      Contact
                    </NavLink>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </header>
      </div>
    );
  }
}
