import React, { Component } from "react";
import "./Wedding.css";
import BackGround from "../../components/BackGround/BackGround";
import Header from "../../components/Header/Header";
import LightGallery from "lightgallery/react";
import lgZoom from "lightgallery/plugins/zoom";
import fjGallery from "flickr-justified-gallery";

import photo_0 from "../../assets/Wedding/Darshana & Pavan/_DSC7392.jpg";
import photo_1 from "../../assets/Wedding/Darshana & Pavan/DSC04604.jpg";
import photo_2 from "../../assets/Wedding/Darshana & Pavan/DSC04689.jpg";
import photo_3 from "../../assets/Wedding/Darshana & Pavan/DSC04528.jpg";
import photo_4 from "../../assets/Wedding/Darshana & Pavan/DSC04717.jpg";
import photo_5 from "../../assets/Wedding/Darshana & Pavan/DSC04664.jpg";
import photo_6 from "../../assets/Wedding/Darshana & Pavan/DSC04718-2.jpg";
import photo_7 from "../../assets/Wedding/Darshana & Pavan/DSC04665.jpg";
import photo_8 from "../../assets/Wedding/Darshana & Pavan/DSC04667.jpg";
import photo_9 from "../../assets/Wedding/Darshana & Pavan/DSC04666.jpg";
import photo_10 from "../../assets/Wedding/Darshana & Pavan/DSC04657.jpg";
import photo_11 from "../../assets/Wedding/Darshana & Pavan/DSC04696.jpg";
import photo_12 from "../../assets/Wedding/Darshana & Pavan/DSC04650.jpg";
import photo_13 from "../../assets/Wedding/Darshana & Pavan/DSC04547.jpg";
import photo_14 from "../../assets/Wedding/Darshana & Pavan/DSC04630.jpg";
import photo_15 from "../../assets/Wedding/Drashti & manthan/DSC03353.jpg";
import photo_16 from "../../assets/Wedding/Drashti & manthan/VBP_1172.jpg";
import photo_17 from "../../assets/Wedding/Drashti & manthan/DSC03356.jpg";
import photo_18 from "../../assets/Wedding/Drashti & manthan/VBP_1162.jpg";
import photo_19 from "../../assets/Wedding/Drashti & manthan/DSC03355.jpg";
import photo_20 from "../../assets/Wedding/Drashti & manthan/VBP_1149.jpg";
import photo_21 from "../../assets/Wedding/Drashti & manthan/VBP_1338.jpg";
import photo_22 from "../../assets/Wedding/Drashti & manthan/DSC03327.jpg";
import photo_23 from "../../assets/Wedding/Drashti & manthan/DSC01687.jpg";
import photo_24 from "../../assets/Wedding/Drashti & manthan/DSC03735.jpg";
import photo_25 from "../../assets/Wedding/Drashti & manthan/DSC04310.jpg";
import photo_26 from "../../assets/Wedding/Drashti & manthan/VBP_1260.jpg";
import photo_27 from "../../assets/Wedding/Drashti & manthan/VBP_1333.jpg";
import photo_28 from "../../assets/Wedding/Drashti & manthan/VBP_1095.jpg";
import photo_29 from "../../assets/Wedding/Drashti & manthan/DSC04492.jpg";
import photo_30 from "../../assets/Wedding/Drashti & manthan/DSC04168.jpg";
import photo_31 from "../../assets/Wedding/Drashti & manthan/VBP_1181.jpg";
import photo_32 from "../../assets/Wedding/Drashti & manthan/VBP_1182.jpg";
import photo_33 from "../../assets/Wedding/Kajol & Parth/DSC03557.jpg";
import photo_34 from "../../assets/Wedding/Kajol & Parth/DSC03636.jpg";
import photo_35 from "../../assets/Wedding/Kajol & Parth/DSC03948.jpg";
import photo_36 from "../../assets/Wedding/Kajol & Parth/DSC03976.jpg";
import photo_37 from "../../assets/Wedding/Kajol & Parth/DSC03988.jpg";
import photo_38 from "../../assets/Wedding/Kajol & Parth/DSC03938.jpg";
import photo_39 from "../../assets/Wedding/Kajol & Parth/DSC03656.jpg";
import photo_40 from "../../assets/Wedding/Kajol & Parth/DSC03697.jpg";
import photo_41 from "../../assets/Wedding/Kajol & Parth/DSC03651.jpg";
import photo_42 from "../../assets/Wedding/Kajol & Parth/DSC03692.jpg";
import photo_43 from "../../assets/Wedding/Kajol & Parth/DSC03644.jpg";
import photo_44 from "../../assets/Wedding/Kajol & Parth/DSC03685.jpg";
import photo_45 from "../../assets/Wedding/Kajol & Parth/DSC03688.jpg";
import photo_46 from "../../assets/Wedding/Kajol & Parth/DSC03661.jpg";
import photo_47 from "../../assets/Wedding/Kajol & Parth/DSC03659.jpg";
import photo_48 from "../../assets/Wedding/Kajol & Parth/DSC03629.jpg";
import photo_49 from "../../assets/Wedding/Kajol & Parth/DSC03559.jpg";
import photo_50 from "../../assets/Wedding/Kajol & Parth/DSC03954.jpg";
import photo_51 from "../../assets/Wedding/Kajol & Parth/DSC03968.jpg";
import photo_52 from "../../assets/Wedding/Kajol & Parth/DSC03612.jpg";
import CopyRights from "../../components/CopyRights/CopyRights";

export default class Wedding extends Component {
  constructor(props) {
    super(props);

    this.state = {
      photos: [
        { id: 0, src: photo_0 },
        { id: 1, src: photo_1 },
        { id: 2, src: photo_2 },
        { id: 3, src: photo_3 },
        { id: 4, src: photo_4 },
        { id: 5, src: photo_5 },
        { id: 6, src: photo_6 },
        { id: 7, src: photo_7 },
        { id: 8, src: photo_8 },
        { id: 9, src: photo_9 },
        { id: 10, src: photo_10 },
        { id: 11, src: photo_11 },
        { id: 12, src: photo_12 },
        { id: 13, src: photo_13 },
        { id: 14, src: photo_14 },
        { id: 15, src: photo_15 },
        { id: 16, src: photo_16 },
        { id: 17, src: photo_17 },
        { id: 18, src: photo_18 },
        { id: 19, src: photo_19 },
        { id: 20, src: photo_20 },
        { id: 21, src: photo_21 },
        { id: 22, src: photo_22 },
        { id: 23, src: photo_23 },
        { id: 24, src: photo_24 },
        { id: 25, src: photo_25 },
        { id: 26, src: photo_26 },
        { id: 27, src: photo_27 },
        { id: 28, src: photo_28 },
        { id: 29, src: photo_29 },
        { id: 30, src: photo_30 },
        { id: 31, src: photo_31 },
        { id: 32, src: photo_32 },
        { id: 33, src: photo_33 },
        { id: 34, src: photo_34 },
        { id: 35, src: photo_35 },
        { id: 36, src: photo_36 },
        { id: 37, src: photo_37 },
        { id: 38, src: photo_38 },
        { id: 39, src: photo_39 },
        { id: 40, src: photo_40 },
        { id: 41, src: photo_41 },
        { id: 42, src: photo_42 },
        { id: 43, src: photo_43 },
        { id: 44, src: photo_44 },
        { id: 45, src: photo_45 },
        { id: 46, src: photo_46 },
        { id: 47, src: photo_47 },
        { id: 48, src: photo_48 },
        { id: 49, src: photo_49 },
        { id: 50, src: photo_50 },
        { id: 51, src: photo_51 },
        { id: 52, src: photo_52 },
      ],
    };
  }

  componentDidMount() {
    fjGallery(document.querySelectorAll(".gallery"), {
      itemSelector: ".gallery__item",
      rowHeight: 800,
      lastRow: "start",
      gutter: 2,
      rowHeightTolerance: 0.1,
      calculateItemsHeight: true,
    });
  }
  render() {
    return (
      <div className="relative h-screen w-screen overflow-y-auto">
        {/* <BackGround show_bg_img={false} /> */}
        <Header />
        <div className="relative z-10 min-h-screen flex flex-col items-center justify-center pt-20">
          <h1 className="text-4xl text-white font-bold mb-8 text-center">
            Wedding
          </h1>

          <LightGallery
            plugins={[lgZoom]}
            mode="lg-fade"
            pager={false}
            thumbnail={true}
            galleryId={"wedding"}
            autoplayFirstVideo={false}
            elementClassNames={"gallery"}
            mobileSettings={{
              controls: false,
              showCloseIcon: true,
              download: false,
              rotate: false,
            }}
          >
            {this.state.photos.map((photo) => (
              <a
                className="gallery__item relative group cursor-pointer p-1"
                data-src={photo.src}
                key={photo.index}
                href={photo.src}
              >
                <img
                  className="img-responsive w-full h-full object-cover"
                  src={photo.src}
                  alt={photo.name}
                />
              </a>
            ))}
          </LightGallery>
        </div>
        <CopyRights />
      </div>
    );
  }
}
