import React, { Component } from "react";
import photo_0 from "../../assets/Pre-Wedding/Drashti & Manthan/DSC00328.jpg";
import photo_1 from "../../assets/Pre-Wedding/Drashti & Manthan/DSC09262.jpg";
import photo_2 from "../../assets/Pre-Wedding/Kajol & Parth/DSC03977.jpg";
import photo_3 from "../../assets/Wedding/Darshana & Pavan/DSC04547.jpg";
import photo_4 from "../../assets/Wedding/Drashti & manthan/VBP_1181.jpg";
import photo_5 from "../../assets/Wedding/Drashti & manthan/VBP_1172.jpg";
import photo_6 from "../../assets/Wedding/Kajol & Parth/DSC03938.jpg";
import "./Portfolio.css";
import { NavLink } from "react-router-dom";

import "tailwindcss/tailwind.css";
import Isotope from "isotope-layout";

export default class Portfolio extends Component {
  constructor(props) {
    super(props);

    this.state = {
      images: [
        { src: photo_0, tag: "prewedding" },
        { src: photo_1, tag: "prewedding" },
        { src: photo_2, tag: "prewedding" },
        { src: photo_3, tag: "wedding" },
        { src: photo_4, tag: "wedding" },
        { src: photo_5, tag: "wedding" },
        { src: photo_6, tag: "wedding" },
      ],
      filter: "*",
      activeButton: "All",
    };
    this.galleryRef = React.createRef();
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.sleep = this.sleep.bind(this);
    this.allButtonRef = React.createRef();
  }

  handleFilterChange = (event) => {
    const filterValue = event.currentTarget.getAttribute("data-filter");
    this.setState({ filter: filterValue }, () => {
      this.$galleryContainer.arrange({ filter: filterValue });
    });
    console.log(event);
    this.setState({ activeButton: event.target.value });
  };

  sleep = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  async componentDidMount() {
    this.$galleryContainer = new Isotope(this.galleryRef.current, {
      itemSelector: ".item",
      layoutMode: "fitRows",
    });

    await this.sleep(1000);
    console.log("Clicking");
    this.setState({ filter: "*" }, () => {
      this.$galleryContainer.arrange({ filter: "*" });
    });
    this.setState({ activeButton: "All" });
    await this.sleep(1000);
    this.setState({ filter: "*" }, () => {
      this.$galleryContainer.arrange({ filter: "*" });
    });
    this.setState({ activeButton: "All" });
    await this.sleep(1000);
    this.setState({ filter: "*" }, () => {
      this.$galleryContainer.arrange({ filter: "*" });
    });
    this.setState({ activeButton: "All" });
    // await this.sleep(1000);
    // this.setState({ filter: "*" }, () => {
    //   this.$galleryContainer.arrange({ filter: "*" });
    // });
    // this.setState({ activeButton: "All" });
    // this.setState({ activeButton: "All" });
    // if (this.allButtonRef.current) {
    //   this.allButtonRef.current.click();
    // }
  }

  render() {
    const { activeButton, images } = this.state;
    const buttonClass = (buttonName) =>
      `text-center py-2 px-4 font-bold rounded-lg duration-100 ${
        activeButton === buttonName
          ? "bg-[#BF9E0B] text-white"
          : "bg-white text-black hover:bg-[#BF9E0B] hover:text-white"
      }`;

    return (
      <div className="bg-[rgb(57,13,13)] pt-10 pb-10">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
          <p className="text-5xl font-extrabold text-white">
            Our <span className="text-[#BF9E0B]">Portfolio</span>
          </p>
        </div>
        <section className="py-12 px-8 md:px-16">
          <main className="">
            <div className="button-group text-center mb-10 space-x-4">
              <button
                className={buttonClass("All")}
                data-filter="*"
                onClick={this.handleFilterChange}
                value="All"
                ref={this.allButtonRef}
              >
                All
              </button>
              <button
                className={buttonClass("Wedding")}
                data-filter=".wedding"
                onClick={this.handleFilterChange}
                value="Wedding"
              >
                Wedding
              </button>
              <button
                className={buttonClass("PreWedding")}
                data-filter=".prewedding"
                onClick={this.handleFilterChange}
                value="PreWedding"
              >
                PreWedding
              </button>
            </div>

            <div
              className="gallery_1 flex  justify-center -m-2"
              ref={this.galleryRef}
            >
              {this.state.images.map((image, index) => (
                <div
                  key={index}
                  className={`item ${image.tag} w-full md:w-1/2 lg:w-1/3 p-2`}
                >
                  <div className="overflow-hidden">
                    <img
                      src={image.src}
                      alt={`Portfolio ${index}`}
                      className="w-full h-full object-cover transition-transform duration-300 transform hover:scale-110"
                    />
                  </div>
                </div>
              ))}
            </div>
          </main>
        </section>
        <div className="flex justify-center w-full">
          <NavLink to="/wedding">
            <button className="text-center py-2 px-4 font-bold rounded-lg duration-100 bg-white text-black hover:bg-[#BF9E0B] hover:text-white">
              See More
            </button>
          </NavLink>
        </div>
      </div>
    );
  }
}
