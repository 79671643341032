import React, { Component } from "react";
import Background_image from "../../assets/background_images/bg.png";

import ParticalBG from "../ParticalBG/ParticleBG";

export default class BackGround extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show_bg_img: props.show_bg_img,
    };
  }
  render() {
    return (
      <div className="fixed inset-0 z-0">
        {/* <ParticalBG /> */}
        {/* {this.state.show_bg_img && (
          <img
            src={Background_image}
            alt="Background"
            className="object-contain h-full w-full"
          />
        )} */}
      </div>
    );
  }
}
