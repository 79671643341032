import React, { Component } from "react";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Home from "./pages/Home/Home";
import Portfolio from "./pages/Wedding/Wedding";
import About from "./pages/PreWedding/PreWedding";
import ContactUs from "./pages/ContactUs/ContactUs";
import { Helmet } from "react-helmet";

export default class App extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }
  componentDidMount() {
    if (window.fbq) {
      console.log("Working");
      // window.fbq("track", "Lead");
    } else {
      console.error("Facebook Pixel not initialized");
    }
  }
  render() {
    return (
      <div>
        <Helmet>
          <script>
            {`
              !function(f,b,e,v,n,t,s)
              {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
              n.callMethod.apply(n,arguments):n.queue.push(arguments)};
              if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
              n.queue=[];t=b.createElement(e);t.async=!0;
              t.src=v;s=b.getElementsByTagName(e)[0];
              s.parentNode.insertBefore(t,s)}(window, document,'script',
              'https://connect.facebook.net/en_US/fbevents.js');
              fbq('init', '1840755883075377');
              
            `}
            {/* fbq('track', 'PageView'); */}
          </script>
        </Helmet>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />}></Route>
            <Route path="/wedding" element={<Portfolio />}></Route>
            <Route path="/prewedding" element={<About />}></Route>
            <Route path="/contact" element={<ContactUs />}></Route>
          </Routes>
        </BrowserRouter>
      </div>
    );
  }
}
