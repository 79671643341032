import React, { Component } from "react";

export default class CopyRights extends Component {
  render() {
    return (
      <div>
        <div className="text-white text-center text-sm p-2 bg-[rgb(57,13,13)]">
          <p>Copyright © 2024 The Weddings Vow. All Rights Reserved.</p>
        </div>
      </div>
    );
  }
}
