import React, { Component } from "react";

import Header from "../../components/Header/Header";

import { FaUser, FaMapMarkerAlt, FaPhoneAlt, FaEnvelope } from "react-icons/fa";

import Logo from "../../assets/Logo_2.png";
import Name from "../../assets/Name.png";
import CopyRights from "../../components/CopyRights/CopyRights";
import { NavLink } from "react-router-dom";

export default class ContactUs extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      email: "",
      phone_number: "",
      message: "",
      errors: {},
      show_thankyou: false,
    };

    this.validate = this.validate.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    if (window.fbq) {
      // window.fbq("track", "Contact");
    } else {
      console.error("Facebook Pixel not initialized");
    }
  }

  validate() {
    let tempErrors = {};
    tempErrors.name = this.state.name ? "" : "Name is required.";

    if (!this.state.email) {
      tempErrors.email = "Email is required.";
    } else if (!/\S+@\S+\.\S+/.test(this.state.email)) {
      tempErrors.email = "Email is not valid.";
    }

    // if (!this.state.phone_number) {
    //   tempErrors.phone_number = "Mobile No is required.";
    // } else if (!/^\+?(\d.*){3,}$/.test(this.state.phone_number)) {
    //   tempErrors.phone_number = "Mobile No is not valid.";
    // }
    if (!this.state.phone_number) {
      tempErrors.phone_number = "Mobile No is required.";
    } else if (!/^\d{10}$/.test(this.state.phone_number)) {
      tempErrors.phone_number = "Mobile No must be exactly 10 digits.";
    }

    tempErrors.message = this.state.message ? "" : "Message is required.";

    this.setState({ errors: tempErrors });
    return Object.values(tempErrors).every((x) => x === "");
  }

  handleChange(event) {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  }

  async handleSubmit(event) {
    event.preventDefault();
    if (this.validate()) {
      // Handle form submission
      const { name, email, phone_number, message } = this.state;
      const formUrl =
        "https://docs.google.com/forms/d/e/1FAIpQLSdk8k1mblns477rXwTwuJSjVnxyEdmeskSPvSXGiTP3WJIRkA/formResponse";
      const formData = new FormData();
      formData.append("entry.121945866", name);
      formData.append("emailAddress", email);
      formData.append("entry.327693208", phone_number);
      formData.append("entry.168357687", message);
      console.log("Form submitted", this.state);
      const response = await fetch(formUrl, {
        method: "POST",
        mode: "no-cors",
        body: formData,
      })
        .then((res) => {
          console.log(res);
          //alert("Form submitted successfully!");
          this.setState({
            name: "",
            email: "",
            phone_number: "",
            message: "",
            show_thankyou: true,
          });
        })

        .catch((error) => {
          //alert("Error submitting form.");
          console.error("Error:", error);
        });
    }
  }

  render() {
    return (
      <div className="relative h-screen w-screen overflow-y-auto">
        {/* <BackGround show_bg_img={false} /> */}
        <Header />
        {this.state.show_thankyou ? (
          <div className="flex items-center justify-center min-h-screen ">
            <div className="text-center">
              <img
                src={Logo}
                alt="Thank You"
                className="mx-auto mb-6 w-40 h-40 md:w-48 md:h-48 lg:w-56 lg:h-56"
              />
              <p className="text-4xl md:text-5xl lg:text-6xl font-extrabold text-white">
                Thank you!
              </p>
              <div className="flex justify-center w-full mt-10">
                <NavLink to="/">
                  <button className="text-center py-2 px-4 font-bold rounded-lg duration-100 bg-black text-white hover:bg-[#BF9E0B] hover:text-white">
                    Go to Home
                  </button>
                </NavLink>
              </div>
            </div>
          </div>
        ) : (
          <div className="relative z-10 min-h-screen flex flex-col items-center justify-center pt-20">
            <h1 className="text-4xl text-white font-bold mb-8 text-center">
              Contact Me
            </h1>
            <div className="w-full max-w-6xl flex flex-col md:flex-row gap-8 p-8 md:p-8">
              <div className="w-full md:w-1/2 p-8">
                <h2 className="text-3xl text-white font-bold mb-4 text-center">
                  Message Me
                </h2>
                <form onSubmit={this.handleSubmit} className="space-y-8">
                  <div>
                    <input
                      type="text"
                      name="name"
                      value={this.state.name}
                      onChange={this.handleChange}
                      className="w-full p-3 rounded-lg bg-[rgba(20,20,20,0.87)] text-white "
                      placeholder="Name"
                    />
                    {this.state.errors.name && (
                      <p className="text-red-500 text-sm">
                        {this.state.errors.name}
                      </p>
                    )}
                  </div>
                  <div>
                    <input
                      type="email"
                      name="email"
                      value={this.state.email}
                      onChange={this.handleChange}
                      className="w-full p-3 rounded-lg bg-[rgba(20,20,20,0.87)] text-white"
                      placeholder="Email"
                    />
                    {this.state.errors.email && (
                      <p className="text-red-500 text-sm">
                        {this.state.errors.email}
                      </p>
                    )}
                  </div>

                  <div>
                    <input
                      type="text"
                      name="phone_number"
                      value={this.state.phone_number}
                      onChange={this.handleChange}
                      min={10}
                      max={10}
                      className="w-full p-3 rounded-lg bg-[rgba(20,20,20,0.87)] text-white"
                      placeholder="Mobile No."
                    />
                    {this.state.errors.phone_number && (
                      <p className="text-red-500 text-sm">
                        {this.state.errors.phone_number}
                      </p>
                    )}
                  </div>
                  <div>
                    <textarea
                      name="message"
                      value={this.state.message}
                      onChange={this.handleChange}
                      className="w-full p-3 rounded-lg bg-[rgba(20,20,20,0.87)] text-white"
                      rows="5"
                      placeholder="Message"
                    />
                    {this.state.errors.message && (
                      <p className="text-red-500 text-sm">
                        {this.state.errors.message}
                      </p>
                    )}
                  </div>
                  <button
                    type="submit"
                    className="w-full text-lg py-2 px-4 bg-[rgba(0,0,0,0.68)] text-white font-bold rounded-md hover:bg-[rgba(0,0,0,1)] duration-100"
                  >
                    Send Message
                  </button>
                </form>
              </div>

              <div className="relative w-full md:w-1/2 bg-cover bg-center rounded-3xl py-52 bg-black">
                <div className="absolute inset-0  bg-opacity-50 rounded-3xl flex flex-col items-center justify-center space-y-4 p-8">
                  <div className="flex flex-row p-10">
                    <img src={Logo} alt="Logo" className="h-8 md:h-10 mr-4" />
                    <img
                      src={Name}
                      alt="Name"
                      className="h-8 md:h-10 mr-4 mt-2"
                    />
                  </div>
                  <h2 className="text-3xl font-bold text-white mb-4 text-center">
                    Contact Info
                  </h2>
                  <div className="flex items-center text-white">
                    <FaUser className="mr-3" />
                    <span className="text-lg">Sagar Chauhan</span>
                  </div>
                  <div className="flex items-center text-white">
                    <FaMapMarkerAlt className="mr-3" />
                    <span className="text-lg">Gandhinagar, Gujarat</span>
                  </div>
                  <div className="flex items-center text-white">
                    <FaPhoneAlt className="mr-3" />
                    <span className="text-lg">+91 94090 60204</span>
                  </div>
                  <div className="flex items-center text-white">
                    <FaEnvelope className="mr-3" />
                    <span className="text-lg">theweddingsvow@gmail.com</span>
                  </div>
                </div>
              </div>
            </div>
            <CopyRights />
          </div>
        )}
      </div>
    );
  }
}
